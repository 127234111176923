import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components/macro"
import { GatsbyImage } from "gatsby-plugin-image"

import Meta from "@components/atoms/Meta"
import { device } from "@utils/styledUtils"

const Card = styled.div`
  background: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.025);
  border-radius: 5px;

  @media ${device.tablet} {
    grid-column: span 2;
    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }

    &:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }
`
const CardImage = styled(GatsbyImage)`
  border-radius: 5px 5px 0 0;
`
const Body = styled.div`
  padding: 1em;
`
const Footer = styled.div`
  padding: 0 1em 1em;
`
export default function BlogCard({ post }) {
  return (
    <Card>
      {post.cover && (
        <CardImage
          alt={post.imageAlt || post.title}
          image={post.cover.localFile.childImageSharp.gatsbyImageData}
          loading="eager"
        />
      )}

      <Body>
        <p>{post.excerpt}</p>
        <Link to={`/blog/${post.slug}`} rel="next">
          Read more →
        </Link>
      </Body>
      <Footer>
        <Meta>
          <span>Published {post.publishedAt}</span>
        </Meta>
      </Footer>
    </Card>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    slug
    title
    description
    publishedAt(fromNow: true)
    cover {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77)
        }
      }
    }
  }
`
