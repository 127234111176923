import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components/macro"

import Section from "@components/atoms/Section"
import Container from "@components/atoms/Container"
import BlogCard from "@components/organisms/BlogCard"
import { device } from "@utils/styledUtils"

const CardsContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1em;
  row-gap: 1em;
  margin-bottom: 1em;
  @media ${device.tablet} {
    grid-template-columns: repeat(6, 1fr);
  }
`

export default function Blog({ data }) {
  const { allStrapiArticle } = data
  return (
    <>
      <Section>
        <CardsContainer>
          {allStrapiArticle.nodes.map(post => (
            <BlogCard key={post.id} post={post} />
          ))}
        </CardsContainer>
      </Section>
    </>
  )
}

export const query = graphql`
  {
    allStrapiArticle {
      nodes {
        ...ArticleCard
      }
    }
  }
`
