import React from "react"
import styled from "styled-components/macro"
import { device } from "@utils/styledUtils"

interface SectionProps {
  readonly light: boolean
}

const Section = styled.section<SectionProps>`
  padding: 3em;
  ${({ light }) => light && `background: #f1f1f1;`}
  @media ${device.mobile} {
    padding: 2em;
  }
`

// export default function Section() {
//     return (
//         <div>

//         </div>
//     )
// }

export default Section
